<template>
    <div class="bg-neutral-950">
        <!-- header -->
        <NewHeader></NewHeader>

        <!-- header END -->
        <!-- 1.Hero -->

        <ColorBg>
            <div id="banner-top" class="relative mx-auto max-w-7xl px-6 pb-16 pt-24 sm:py-40 lg:px-8">
                <div
                    class="relative z-40 mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8 items-center">
                    <div class="mt-14 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                        <h1
                            class="font-extrabold text-5xl leading-[1.2] text-gray-200 mb-7 max-[450px]:text-3xl max-[450px]:text-center">
                            Best <span class="text-orange-600">AI Face Swapper</span> Online Website for Videos
                            &amp;
                            Photos
                        </h1>
                        <p class="text-base font-normal text-white/50 mb-7 max-[450px]:text-center">
                            Change faces in videos and photos with 3 simple clicks. Generate
                            premium
                            quality and realistic face swap with Bestfaceswap.ai.
                        </p>
                        <div class="flex flex-col min-[450px]:flex-row items-center gap-5 mb-10 xl:mb-24">
                            <FaceSwapBtn></FaceSwapBtn>
                        </div>
                        <div
                            class="flex flex-col min-[450px]:flex-row items-center gap-8 min-[450px]:gap-28 max-[450px]:hidden">
                            <div class="block">
                                <h4
                                    class="max-[450px]:text-center font-extrabold text-2xl leading-9 text-orange-600 mb-2">
                                    150K+</h4>
                                <p class="text-base max-[450px]:text-center font-normal text-white/50">Creators
                                    Trusted
                                </p>
                            </div>
                            <div class="block">
                                <h4
                                    class="max-[450px]:text-center font-extrabold text-2xl leading-9 text-orange-600 mb-2">
                                    High quality</h4>
                                <p class="text-base max-[450px]:text-center font-normal text-white/50">Realistic
                                    face
                                    swaps
                                </p>
                            </div>
                        </div>
                    </div>
                    <video
                        class="h-auto mt-10 aspect-[6/5] w-full max-w-none rounded-3xl object-cover sm:mt-16 lg:max-w-none xl:row-span-2 xl:row-end-2"
                        loop autoplay muted playsinline poster="/idx/bestfaceswap_mobile.jpg">
                        <source src="/idx/bestfaceswap_mobile.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </ColorBg>
        <!-- 1.Hero END -->

        <!-- 2.Face Swap feature -->
        <v-lazy>
            <FaceSwapFeatures></FaceSwapFeatures>
        </v-lazy>
        <!-- 2.Face Swap feature END -->

        <!-- Features -->
        <v-lazy>
            <Features></Features>
        </v-lazy>
        <!-- Features END-->

        <!-- 3.Step -->
        <v-lazy>
            <ThreeSteps></ThreeSteps>
        </v-lazy>
        <!-- 3.Step END -->

        <!-- 4.testimonial -->
        <v-lazy>
            <Testimonial :items="items"></Testimonial>
        </v-lazy>
        <div class="mt-12 w-full flex justify-center">
            <FaceSwapBtn></FaceSwapBtn>
        </div>
        <!-- 4.testimonial END -->

        <!-- 5.FAQ -->
        <v-lazy>
            <Faq></Faq>
        </v-lazy>
        <!-- 5.FAQ END -->

        <!-- 6.CTA -->
        <v-lazy>
            <Cta></Cta>
        </v-lazy>
        <!-- 6.CTA END-->


        <!-- Button -->
        <div class="fixed bottom-0 left-0 w-full lg:hidden z-50">
            <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
            <div class="relative w-full flex justify-center p-4">
                <FaceSwapBtn></FaceSwapBtn>
            </div>
        </div>
        <!-- Button END -->
        <!-- <Footer></Footer> -->
        <NewFooter class="!absolute"></NewFooter>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
// import '~/assets/js/BlurGradientBg.min.js'
import NewHeader from '~/components/NewHeader.vue';
import Footer from '~/components/Footer.vue';
import NewFooter from '~/components/NewFooter.vue';
import FaceSwapBtn from '~/components/FaceSwapBtn.vue';
import Testimonial from '~/components/Testimonial.vue';
import Faq from '~/components/Faq.vue';
import Cta from '~/components/Cta.vue';
import ThreeSteps from '~/components/ThreeSteps.vue';
import Features from '~/components/Features.vue';
import FaceSwapFeatures from '~/components/FaceSwapFeatures.vue';
import ColorBg from '~/components/ColorBg.vue';
useHead({
    title: 'Next Level AI Face Swap Online Website for Videos & Photos',
    meta: [
        {
            name: 'description',
            content: "Swap face in videos, gifs & photos with the best AI face swap generator. Get up to 90% similarity on final faceswap results. Try it Now to enjoy 50% off!"
        }
    ],
    link: [{ rel: 'canonical', href: 'https://www.bestfaceswap.ai/' }]
});

const items = [
    {
        avatar: '/idx/avater_01.png',
        name: 'Jessica Taylor',
        content: 'BestFaceSwap.AI is amazing! I used it to create a funny video for my friend\'s birthday, and it turned out perfectly. The face swap was so realistic, and the whole process was super easy. The best part was seeing everyone\'s reactions—they couldn\'t believe how natural it looked.'
    },
    {
        avatar: '/idx/avater_02.png',
        name: 'Laura Smith',
        content: 'I was impressed by how natural the face swaps looked on BestFaceSwap.AI. I tried several other online apps before, but this one definitely stands out in terms of quality and ease of use. The interface is intuitive, and the results are stunning. Recommend!'
    },
    {
        avatar: '/idx/avater_03.png',
        name: 'Nick Betcher',
        content: 'It’s the best option for face swapping. I’m not very tech-savvy, but I had no trouble navigating the site and swapping faces in my videos. Fast processing speed is also a huge bonus. I was able to create several face swap videos in no time, and the quality was consistently excellent.'
    },
    {
        avatar: '/idx/avater_04.png',
        name: 'Sophia Rodriguez',
        content: 'This is a fantastic tool for anyone looking to have fun with their photos and videos. I swapped faces in a few old family photos, and everyone loved it. The results were surprisingly realistic, and it brought back so many memories with a fun twist. Great job, BestFaceSwap.AI!'
    },
    {
        avatar: '/idx/avater_05.png',
        name: 'Jonathan Keppler',
        content: 'The quality of the face swaps on BestFaceSwap.AI is top-notch. I used it for a marketing campaign, and the results were fantastic. It really helped us create unique and engaging content that stood out. The realistic swaps added a fun element that resonated with our audience.'
    },
    {
        avatar: '/idx/avater_06.png',
        name: 'Ethan Nguyen',
        content: 'It exceeded my expectations. I created a personalized video message for my sister’s wedding, and it was a big hit. The face swaps were so natural, and the entire process was quick and easy. Everyone at the wedding loved it and kept asking how I made it. Thank you for making such a special moment even more memorable!'
    },
    {
        avatar: '/idx/avater_07.png',
        name: 'Mia Garcia',
        content: 'If you’re looking for a high-quality face swap app, BestFaceSwap.AI is the way to go. The technology is advanced, and the results are incredibly realistic. I love that there’s no wait time for premium users. I can get my face swaps done instantly without any hassle. Fantastic service and worth every penny!'
    },
    {
        avatar: '/idx/avater_08.png',
        name: 'Noah Wilson',
        content: 'I used BestFaceSwap.AI for a school project, and it was a huge success. The students loved seeing themselves as historical figures in the videos. It made the lessons much more engaging and fun. Great tool for educators looking to bring a creative twist to their teaching methods.'
    },
    {
        avatar: '/idx/avater_09.png',
        name: 'Lily Evans',
        content: 'BestFaceSwap.AI is a game-changer. I used it to create some hilarious memes, and they turned out amazing. The face swaps are so realistic, and the site is very easy to use. My friends and I had a blast coming up with new meme ideas and sharing them. Definitely worth trying if you want to add some humor to your social media!'
    }
];
</script>

<template>
    <!-- 5.FAQ -->
    <section class="mx-auto max-w-7xl px-6 lg:px-8 pt-8 sm:pt-20">
        <div class="text-center">
            <h2 class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl text-center">
                Frequently asked questions
            </h2>
            <p class="mt-6 text-lg leading-8 text-white/50">Trusted by more than 1000+ customers</p>
        </div>
        <div class="w-full pt-12">
            <div class="accordion-group">
                <div v-for="(item, index) in faqs" :key="index"
                    class="accordion p-4 sm:p-8 mb-7 transition-all duration-500 bg-zinc-900 rounded-2xl hover:bg-zinc-800 cursor-pointer"
                    @click="toggleAccordion(index)">
                    <div class="accordion-toggle group inline-flex items-center justify-between text-md sm:text-xl leading-8 text-white/50 w-full transition duration-500 text-left"
                        :class="{ active: index === activeIndex }">
                        <h3>{{ item.question }}</h3>
                        <svg class="text-neutral-500 transition duration-500 group-hover:text-orange-400 transform"
                            :class="{ 'rotate-180': index === activeIndex }" width="22" height="22" viewBox="0 0 22 22"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                stroke="currentColor" stroke-width="1.6" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div class="accordion-content w-full overflow-hidden transition-[height] duration-300"
                        :style="{ height: index === activeIndex ? contentHeight : '0px' }" ref="accordionContent">
                        <p class="text-base text-white/30 leading-6 pt-3">{{ item.answer }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const faqs = ref([
    {
        question: 'What Is AI Face Swap?',
        answer:
            'AI face swap technology uses artificial intelligence to replace one face with another in photos or videos, ensuring a realistic look by aligning and blending the new face with the original image. This technology often utilizes deep learning models like GANs to achieve high-quality results.',
    },
    {
        question: 'Is it Safe to Use Bestfaceswap.ai for Face Swapping?',
        answer:
            'Yes. Using BestFaceSwap.AI for face swapping is completely safe. We prioritize your privacy and protect your data. Only you can view your data, and all temporary files are automatically deleted once the face swap is complete.',
    },
    {
        question: 'Does BestFaceSwap.AI Support Multiple Face Swaps?',
        answer:
            'Multiple face swapping is coming soon. For now, we recommend uploading a video or photo with a single face to get the best results. If you upload an image or video with multiple faces, all the faces will be replaced by one target face.',
    },
    {
        question: 'How Long Does Face Swapping Take?',
        answer:
            'Swapping faces in a photo takes about 6 seconds. For videos, the waiting time required depends on the length and the number of frames.',
    },
    {
        question: 'How Can I Get Better Face Swap Results?',
        answer:
            "Start by choosing a photo or video where the face is clear and well-defined, blurry or low-resolution images and videos can affect the outcome. Make sure the face is taken from a front-facing view and isn't obstructed by objects like hands. Finally, ensure both the original and target faces are well-lit to enhance the overall quality and realism of the face swap.",
    },
    {
        question: 'How Can I Get Customer Support on BestFaceSwap.AI?',
        answer:
            'If you have any questions, please reach out to us at feedback@bestfaceswap.ai. You can get a reply within 24 hours on business days.',
    },
]);

const activeIndex = ref<number | null>(null);

const toggleAccordion = (index: number) => {
    activeIndex.value = activeIndex.value === index ? null : index;
};

const contentHeight = 'auto';
</script>

<style scoped>
.accordion-toggle.active h3 {
    color: #fff;
}

.accordion-toggle.active svg {
    color: #f97316;
}
</style>